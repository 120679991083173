import { ComponentPropsWithoutRef, forwardRef } from "react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";

export const ForwardRefLink = forwardRef<HTMLAnchorElement, ComponentPropsWithoutRef<"a">>(
  ({ href, children, className, "aria-label": ariaLabel, ...props }, ref) => (
    <a href={href} className={className} ref={ref} aria-label={ariaLabel} {...props}>
      {children}
    </a>
  )
);
ForwardRefLink.displayName = "ForwardRefLink";

export type LinkProps = NextLinkProps & Omit<ComponentPropsWithoutRef<"a">, "href">;
export const Link = ({
  href,
  target,
  children,
  className,
  "aria-label": ariaLabel,
  ...props
}: LinkProps) => (
  <NextLink href={href} {...props} passHref>
    <ForwardRefLink className={className} target={target} aria-label={ariaLabel}>
      {children}
    </ForwardRefLink>
  </NextLink>
);
