export const theme = {
  fontFamily: {
    sans: [
      '"IBMPlexSans"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      "sans-serif",
    ],
  },
  fontWeight: { light: 300, normal: 400, semibold: 600, bold: 700 },
  colors: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  lineClamp: { "1": "1", "2": "2", "3": "3", "4": "4", "5": "5", "6": "6" },
  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
  },
  spacing: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
  },
  animation: {
    none: "none",
    spin: "spin 1s linear infinite",
    ping: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    pulse: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    bounce: "bounce 1s infinite",
  },
  backdropBlur: {
    "0": "0",
    none: "0",
    sm: "4px",
    DEFAULT: "8px",
    md: "12px",
    lg: "16px",
    xl: "24px",
    "2xl": "40px",
    "3xl": "64px",
  },
  backdropBrightness: {
    "0": "0",
    "50": ".5",
    "75": ".75",
    "90": ".9",
    "95": ".95",
    "100": "1",
    "105": "1.05",
    "110": "1.1",
    "125": "1.25",
    "150": "1.5",
    "200": "2",
  },
  backdropContrast: {
    "0": "0",
    "50": ".5",
    "75": ".75",
    "100": "1",
    "125": "1.25",
    "150": "1.5",
    "200": "2",
  },
  backdropGrayscale: { "0": "0", DEFAULT: "100%" },
  backdropHueRotate: {
    "0": "0deg",
    "15": "15deg",
    "30": "30deg",
    "60": "60deg",
    "90": "90deg",
    "180": "180deg",
    "-180": "-180deg",
    "-90": "-90deg",
    "-60": "-60deg",
    "-30": "-30deg",
    "-15": "-15deg",
  },
  backdropInvert: { "0": "0", DEFAULT: "100%" },
  backdropOpacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
  },
  backdropSaturate: {
    "0": "0",
    "50": ".5",
    "100": "1",
    "150": "1.5",
    "200": "2",
  },
  backdropSepia: { "0": "0", DEFAULT: "100%" },
  backgroundColor: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  backgroundImage: {
    none: "none",
    "gradient-to-t": "linear-gradient(to top, var(--tw-gradient-stops))",
    "gradient-to-tr": "linear-gradient(to top right, var(--tw-gradient-stops))",
    "gradient-to-r": "linear-gradient(to right, var(--tw-gradient-stops))",
    "gradient-to-br": "linear-gradient(to bottom right, var(--tw-gradient-stops))",
    "gradient-to-b": "linear-gradient(to bottom, var(--tw-gradient-stops))",
    "gradient-to-bl": "linear-gradient(to bottom left, var(--tw-gradient-stops))",
    "gradient-to-l": "linear-gradient(to left, var(--tw-gradient-stops))",
    "gradient-to-tl": "linear-gradient(to top left, var(--tw-gradient-stops))",
  },
  backgroundOpacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
  },
  backgroundPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  backgroundSize: { auto: "auto", cover: "cover", contain: "contain" },
  blur: {
    "0": "0",
    none: "0",
    sm: "4px",
    DEFAULT: "8px",
    md: "12px",
    lg: "16px",
    xl: "24px",
    "2xl": "40px",
    "3xl": "64px",
  },
  brightness: {
    "0": "0",
    "50": ".5",
    "75": ".75",
    "90": ".9",
    "95": ".95",
    "100": "1",
    "105": "1.05",
    "110": "1.1",
    "125": "1.25",
    "150": "1.5",
    "200": "2",
  },
  borderColor: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
    DEFAULT: "currentColor",
  },
  borderOpacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
  },
  borderRadius: {
    none: "0px",
    sm: "0.125rem",
    DEFAULT: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
  borderWidth: {
    "0": "0px",
    "2": "2px",
    "4": "4px",
    "8": "8px",
    DEFAULT: "1px",
  },
  boxShadow: {
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    DEFAULT: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    none: "none",
  },
  caretColor: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  contrast: {
    "0": "0",
    "50": ".5",
    "75": ".75",
    "100": "1",
    "125": "1.25",
    "150": "1.5",
    "200": "2",
  },
  container: {},
  content: { none: "none" },
  cursor: {
    auto: "auto",
    default: "default",
    pointer: "pointer",
    wait: "wait",
    text: "text",
    move: "move",
    help: "help",
    "not-allowed": "not-allowed",
  },
  divideColor: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
    DEFAULT: "currentColor",
  },
  divideOpacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
  },
  divideWidth: {
    "0": "0px",
    "2": "2px",
    "4": "4px",
    "8": "8px",
    DEFAULT: "1px",
  },
  dropShadow: {
    sm: "0 1px 1px rgba(0,0,0,0.05)",
    DEFAULT: ["0 1px 2px rgba(0, 0, 0, 0.1)", "0 1px 1px rgba(0, 0, 0, 0.06)"],
    md: ["0 4px 3px rgba(0, 0, 0, 0.07)", "0 2px 2px rgba(0, 0, 0, 0.06)"],
    lg: ["0 10px 8px rgba(0, 0, 0, 0.04)", "0 4px 3px rgba(0, 0, 0, 0.1)"],
    xl: ["0 20px 13px rgba(0, 0, 0, 0.03)", "0 8px 5px rgba(0, 0, 0, 0.08)"],
    "2xl": "0 25px 25px rgba(0, 0, 0, 0.15)",
    none: "0 0 #0000",
  },
  fill: { current: "currentColor" },
  grayscale: { "0": "0", DEFAULT: "100%" },
  hueRotate: {
    "0": "0deg",
    "15": "15deg",
    "30": "30deg",
    "60": "60deg",
    "90": "90deg",
    "180": "180deg",
    "-180": "-180deg",
    "-90": "-90deg",
    "-60": "-60deg",
    "-30": "-30deg",
    "-15": "-15deg",
  },
  invert: { "0": "0", DEFAULT: "100%" },
  flex: { "1": "1 1 0%", auto: "1 1 auto", initial: "0 1 auto", none: "none" },
  flexGrow: { "0": "0", DEFAULT: "1" },
  flexShrink: { "0": "0", DEFAULT: "1" },
  fontSize: {
    xs: ["0.75rem", { lineHeight: "1rem" }],
    sm: ["0.875rem", { lineHeight: "1.25rem" }],
    base: ["1rem", { lineHeight: "1.5rem" }],
    lg: ["1.125rem", { lineHeight: "1.75rem" }],
    xl: ["1.25rem", { lineHeight: "1.75rem" }],
    "2xl": ["1.5rem", { lineHeight: "2rem" }],
    "3xl": ["1.875rem", { lineHeight: "2.25rem" }],
    "4xl": ["2.25rem", { lineHeight: "2.5rem" }],
    "5xl": ["3rem", { lineHeight: "1" }],
    "6xl": ["3.75rem", { lineHeight: "1" }],
    "7xl": ["4.5rem", { lineHeight: "1" }],
    "8xl": ["6rem", { lineHeight: "1" }],
    "9xl": ["8rem", { lineHeight: "1" }],
  },
  gap: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
  },
  gradientColorStops: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  gridAutoColumns: {
    auto: "auto",
    min: "min-content",
    max: "max-content",
    fr: "minmax(0, 1fr)",
  },
  gridAutoRows: {
    auto: "auto",
    min: "min-content",
    max: "max-content",
    fr: "minmax(0, 1fr)",
  },
  gridColumn: {
    auto: "auto",
    "span-1": "span 1 / span 1",
    "span-2": "span 2 / span 2",
    "span-3": "span 3 / span 3",
    "span-4": "span 4 / span 4",
    "span-5": "span 5 / span 5",
    "span-6": "span 6 / span 6",
    "span-7": "span 7 / span 7",
    "span-8": "span 8 / span 8",
    "span-9": "span 9 / span 9",
    "span-10": "span 10 / span 10",
    "span-11": "span 11 / span 11",
    "span-12": "span 12 / span 12",
    "span-full": "1 / -1",
  },
  gridColumnEnd: {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10",
    "11": "11",
    "12": "12",
    "13": "13",
    auto: "auto",
  },
  gridColumnStart: {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10",
    "11": "11",
    "12": "12",
    "13": "13",
    auto: "auto",
  },
  gridRow: {
    auto: "auto",
    "span-1": "span 1 / span 1",
    "span-2": "span 2 / span 2",
    "span-3": "span 3 / span 3",
    "span-4": "span 4 / span 4",
    "span-5": "span 5 / span 5",
    "span-6": "span 6 / span 6",
    "span-full": "1 / -1",
  },
  gridRowStart: {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    auto: "auto",
  },
  gridRowEnd: {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    auto: "auto",
  },
  gridTemplateColumns: {
    "1": "repeat(1, minmax(0, 1fr))",
    "2": "repeat(2, minmax(0, 1fr))",
    "3": "repeat(3, minmax(0, 1fr))",
    "4": "repeat(4, minmax(0, 1fr))",
    "5": "repeat(5, minmax(0, 1fr))",
    "6": "repeat(6, minmax(0, 1fr))",
    "7": "repeat(7, minmax(0, 1fr))",
    "8": "repeat(8, minmax(0, 1fr))",
    "9": "repeat(9, minmax(0, 1fr))",
    "10": "repeat(10, minmax(0, 1fr))",
    "11": "repeat(11, minmax(0, 1fr))",
    "12": "repeat(12, minmax(0, 1fr))",
    none: "none",
  },
  gridTemplateRows: {
    "1": "repeat(1, minmax(0, 1fr))",
    "2": "repeat(2, minmax(0, 1fr))",
    "3": "repeat(3, minmax(0, 1fr))",
    "4": "repeat(4, minmax(0, 1fr))",
    "5": "repeat(5, minmax(0, 1fr))",
    "6": "repeat(6, minmax(0, 1fr))",
    none: "none",
  },
  height: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    auto: "auto",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    full: "100%",
    screen: "100vh",
  },
  inset: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    auto: "auto",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    full: "100%",
    "-1/2": "-50%",
    "-1/3": "-33.333333%",
    "-2/3": "-66.666667%",
    "-1/4": "-25%",
    "-2/4": "-50%",
    "-3/4": "-75%",
    "-full": "-100%",
  },
  keyframes: {
    spin: { to: { transform: "rotate(360deg)" } },
    ping: { "75%, 100%": { transform: "scale(2)", opacity: "0" } },
    pulse: { "50%": { opacity: ".5" } },
    bounce: {
      "0%, 100%": {
        transform: "translateY(-25%)",
        animationTimingFunction: "cubic-bezier(0.8,0,1,1)",
      },
      "50%": {
        transform: "none",
        animationTimingFunction: "cubic-bezier(0,0,0.2,1)",
      },
    },
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0em",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeight: {
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  listStyleType: { none: "none", disc: "disc", decimal: "decimal" },
  margin: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    auto: "auto",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
  },
  maxHeight: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
    full: "100%",
    screen: "100vh",
  },
  maxWidth: {
    "0": "0rem",
    none: "none",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "7xl": "80rem",
    full: "100%",
    min: "min-content",
    max: "max-content",
    prose: "65ch",
    "screen-sm": "640px",
    "screen-md": "768px",
    "screen-lg": "1024px",
    "screen-xl": "1280px",
    "screen-2xl": "1536px",
  },
  minHeight: { "0": "0px", full: "100%", screen: "100vh" },
  minWidth: {
    "0": "0px",
    full: "100%",
    min: "min-content",
    max: "max-content",
  },
  objectPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  opacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
  },
  order: {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10",
    "11": "11",
    "12": "12",
    first: "-9999",
    last: "9999",
    none: "0",
  },
  outline: {
    none: ["2px solid transparent", "2px"],
    white: ["2px dotted white", "2px"],
    black: ["2px dotted black", "2px"],
  },
  padding: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
  },
  placeholderColor: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  placeholderOpacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
  },
  ringColor: {
    DEFAULT: "#3b82f6",
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  ringOffsetColor: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  ringOffsetWidth: {
    "0": "0px",
    "1": "1px",
    "2": "2px",
    "4": "4px",
    "8": "8px",
  },
  ringOpacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
    DEFAULT: "0.5",
  },
  ringWidth: {
    "0": "0px",
    "1": "1px",
    "2": "2px",
    "4": "4px",
    "8": "8px",
    DEFAULT: "3px",
  },
  rotate: {
    "0": "0deg",
    "1": "1deg",
    "2": "2deg",
    "3": "3deg",
    "6": "6deg",
    "12": "12deg",
    "45": "45deg",
    "90": "90deg",
    "180": "180deg",
    "-180": "-180deg",
    "-90": "-90deg",
    "-45": "-45deg",
    "-12": "-12deg",
    "-6": "-6deg",
    "-3": "-3deg",
    "-2": "-2deg",
    "-1": "-1deg",
  },
  saturate: { "0": "0", "50": ".5", "100": "1", "150": "1.5", "200": "2" },
  scale: {
    "0": "0",
    "50": ".5",
    "75": ".75",
    "90": ".9",
    "95": ".95",
    "100": "1",
    "105": "1.05",
    "110": "1.1",
    "125": "1.25",
    "150": "1.5",
  },
  sepia: { "0": "0", DEFAULT: "100%" },
  skew: {
    "0": "0deg",
    "1": "1deg",
    "2": "2deg",
    "3": "3deg",
    "6": "6deg",
    "12": "12deg",
    "-12": "-12deg",
    "-6": "-6deg",
    "-3": "-3deg",
    "-2": "-2deg",
    "-1": "-1deg",
  },
  space: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
  },
  stroke: { current: "currentColor" },
  strokeWidth: { "0": "0", "1": "1", "2": "2" },
  textColor: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    primary: {
      "100": "#EAF2FB",
      "200": "#BFD7F2",
      "300": "#6AA3E2",
      "400": "#2670C2",
      "500": "#214A78",
    },
    secondary: {
      "100": "#FFF0E6",
      "200": "#FFDAC5",
      "300": "#FD9E5A",
      "400": "#EC6503",
      "500": "#CA5700",
    },
    grey: {
      "100": "#F4F7FF",
      "200": "#CFD4E5",
      "300": "#707999",
      "400": "#3F4660",
      "500": "#DDDFE7",
    },
    greyLight: { "300": "#A5ACC5" },
    error: { main: "#FF3E3E", light: "#FFDDDB", dark: "#DD1A1A" },
    success: { main: "#3B9B62", semiLight: "#ACFFBF", light: "#DCFFE4" },
    warning: { main: "#E98C00", light: "#FDF9D7" },
    info: { main: "#113155", light: "#BFD7F2" },
    backgrounds: { main: "#F3F6FA", card: "#2670C2" },
  },
  textOpacity: {
    "0": "0",
    "5": "0.05",
    "10": "0.1",
    "20": "0.2",
    "25": "0.25",
    "30": "0.3",
    "40": "0.4",
    "50": "0.5",
    "60": "0.6",
    "70": "0.7",
    "75": "0.75",
    "80": "0.8",
    "90": "0.9",
    "95": "0.95",
    "100": "1",
  },
  transformOrigin: {
    center: "center",
    top: "top",
    "top-right": "top right",
    right: "right",
    "bottom-right": "bottom right",
    bottom: "bottom",
    "bottom-left": "bottom left",
    left: "left",
    "top-left": "top left",
  },
  transitionDelay: {
    "75": "75ms",
    "100": "100ms",
    "150": "150ms",
    "200": "200ms",
    "300": "300ms",
    "500": "500ms",
    "700": "700ms",
    "1000": "1000ms",
  },
  transitionDuration: {
    "75": "75ms",
    "100": "100ms",
    "150": "150ms",
    "200": "200ms",
    "300": "300ms",
    "500": "500ms",
    "700": "700ms",
    "1000": "1000ms",
    DEFAULT: "150ms",
  },
  transitionProperty: {
    none: "none",
    all: "all",
    DEFAULT:
      "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    colors: "background-color, border-color, color, fill, stroke",
    opacity: "opacity",
    shadow: "box-shadow",
    transform: "transform",
  },
  transitionTimingFunction: {
    DEFAULT: "cubic-bezier(0.4, 0, 0.2, 1)",
    linear: "linear",
    in: "cubic-bezier(0.4, 0, 1, 1)",
    out: "cubic-bezier(0, 0, 0.2, 1)",
    "in-out": "cubic-bezier(0.4, 0, 0.2, 1)",
  },
  translate: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    full: "100%",
    "-1/2": "-50%",
    "-1/3": "-33.333333%",
    "-2/3": "-66.666667%",
    "-1/4": "-25%",
    "-2/4": "-50%",
    "-3/4": "-75%",
    "-full": "-100%",
  },
  width: {
    "0": "0px",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "14": "3.5rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "28": "7rem",
    "32": "8rem",
    "36": "9rem",
    "40": "10rem",
    "44": "11rem",
    "48": "12rem",
    "52": "13rem",
    "56": "14rem",
    "60": "15rem",
    "64": "16rem",
    "72": "18rem",
    "80": "20rem",
    "96": "24rem",
    auto: "auto",
    px: "1px",
    "0.5": "0.125rem",
    "1.5": "0.375rem",
    "2.5": "0.625rem",
    "3.5": "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screen: "100vw",
    min: "min-content",
    max: "max-content",
  },
  zIndex: {
    "0": "0",
    "10": "10",
    "20": "20",
    "30": "30",
    "40": "40",
    "50": "50",
    auto: "auto",
  },
};
