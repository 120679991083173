import type { ReactNode } from "react";
import cx from "classnames";
import styles from "./Button.module.css";
import { Link } from "@ui-kit/components/link/Link";
import React from "react";

export type ButtonBgColor =
  | "transparent"
  | "primary-100"
  | "primary-400"
  | "secondary-300"
  | "secondary-400"
  | "white"
  | "grey-100"
  | "error-main";

export type ButtonPropsCommon = {
  textColor: string;
  bgColor: ButtonBgColor;
  outlined?: boolean;
  small?: boolean;
  icon?: ReactNode;
  borderless?: boolean;
  rounded?: boolean;
  iconEnd?: boolean;
  children?: ReactNode;
  className?: string;
  textClassName?: string;
  disabled?: boolean;
  onClick?: (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export interface ButtonPropsButton extends ButtonPropsCommon {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

export interface ButtonPropsFake extends ButtonPropsCommon {
  as: "span";
}

export interface ButtonPropsLink extends ButtonPropsCommon {
  href: string;
}

export type ButtonProps = ButtonPropsButton | ButtonPropsLink | ButtonPropsFake;

const bgColorsHoverMap: Record<ButtonBgColor, string | null> = {
  transparent: null,
  "primary-100": "hover:bg-primary-200",
  "primary-400": "hover:bg-primary-500",
  "secondary-300": "hover:bg-secondary-400",
  "secondary-400": "hover:bg-secondary-500",
  white: "hover:bg-grey-100",
  "grey-100": "hover:bg-grey-200",
  "error-main": "hover:bg-error-dark",
};

export const Button = ({
  className,
  textClassName = "ctaButtonText",
  disabled = false,
  textColor,
  bgColor,
  small = false,
  borderless = true,
  outlined = false,
  rounded = true,
  icon,
  iconEnd = false,
  children,
  ...props
}: ButtonProps) => {
  const cxClassName = cx(
    className,
    textClassName,
    "flex justify-center items-center cursor-pointer transition-colors duration-300",
    `text-${textColor} bg-${bgColor}`,
    rounded && "rounded",
    (outlined || !borderless) && `border border-${textColor === "white" ? bgColor : textColor}`,
    outlined ? styles.outlined : styles.contained,
    small ? styles.small : styles.medium,
    {
      border: outlined,
      [styles.disabled]: disabled,
      [styles.withIconStart]: Boolean(icon) && !iconEnd && children,
      [styles.withIconEnd]: Boolean(icon) && iconEnd && children,
      [styles.iconOnly]: Boolean(icon) && children === undefined,
    },
    bgColorsHoverMap[bgColor]
  );
  const content = (
    <>
      {!iconEnd && icon}
      {children}
      {iconEnd && icon}
    </>
  );

  if ("href" in props) {
    return (
      <Link href={props.href} className={cxClassName}>
        {content}
      </Link>
    );
  } else if ("as" in props) {
    const HTMLTag = props.as;
    return (
      <HTMLTag className={cxClassName} {...props}>
        {content}
      </HTMLTag>
    );
  } else {
    const buttonType = props.type ?? "button";
    return (
      <button className={cxClassName} disabled={disabled} type={buttonType} {...props}>
        {content}
      </button>
    );
  }
};
